export const variables = {
	hasuraWsEnpoint: import.meta.env.VITE_GRAPHQL_WS_URL,
	hasuraEndPoint: import.meta.env.VITE_GRAPHQL_URL,
	hasuraAdminSecret: import.meta.env.VITE_ADMIN_SECRET,
	fbApiKey: import.meta.env.VITE_FB_API_KEY,
	fbAuthDomain: import.meta.env.VITE_FB_AUTH_DOMAIN,
	fbProjectId: import.meta.env.VITE_FB_PROJECT_ID,
	fbStorageBucket: import.meta.env.VITE_FB_STORAGE_BUCKET,
	fbAppId: import.meta.env.VITE_FB_APP_ID,
	fbMessageSenderId: import.meta.env.VITE_FB_MESSAGE_SENDER_ID,
	fbMeasurementId: import.meta.env.VITE_FB_MEASUREMENT_ID,
	supabaseUrl: import.meta.env.VITE_SUPABASE_URL,
	supabaseAnonKey: import.meta.env.VITE_SUPABASE_KEY
}
